$(document).ready(function () {
    //Banner Slides
    $('.slidecontainer')
        .cycle({
            fx: 'fade',
            slides: '.hero',
            speed: 'slow',
            timeout: 6000,
            width: '100%',
            fit: 1
        });

    //Testimonial Slides
    $('.testimonial-wrap')
        .cycle({
            fx: 'fade',
            slides: '.testimonial-text',
            speed: 'slow',
            timeout: 6000,
            width: '100%',
            fit: 1
        });

    //Menu Toggle
    var menuToggle = $('#js-mobile-menu').unbind();
    $('#js-navigation-menu').removeClass("show");

    menuToggle.on('click', function (e) {
        e.preventDefault();
        $('#js-navigation-menu').slideToggle(function () {
            if ($('#js-navigation-menu').is(':hidden')) {
                $('#js-navigation-menu').removeAttr('style');
            }
        });
    });

    $('#shopping-cart-nav').find('li').hover(
        function () {
            //show its submenu
            $('ul', this).slideDown(100);
        },
        function () {
            //hide its submenu
            $('ul', this).slideUp(100);
        }
    );

    //Match element heights
    $('.equalise').matchHeight();

    //Form Validation
    $("#contact-form").validate({
        errorLabelContainer: $("#contact-form div.disp-error")
    });

    //Slick carousel
    $('.carousel').slick({
        dots: true,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        centerPadding: '60px',
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    });

    //Swipebox
    $('.swipebox').swipebox({
        hideBarsDelay: 10000
    });

    //Sticky sidemenu
    $('.sidemenu').stickit({
        screenMinWidth: 860
    });

    //Validate regitration form
    jQuery.validator.addMethod("alphanumeric", function (value, element) {
        return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
    });
    jQuery.validator.addMethod("postcodeUK", function (value, element) {
        return this.optional(element) || /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i.test(value);
    });

    $("#signin-form").validate({
        rules: {
            password: {
                required: true,
                alphanumeric: true,
                maxlength: 50,
                minlength: 7
            },
            email: {
                email: true
            }
        },
        messages: {
            password: {
                maxlength: "Passwords cannot be longer than 50 characters",
                minlength: "Passwords must be 7 characters or longer",
                alphanumeric: "Only letters and numbers are allowed"
            },
            email: {
                email: "Please enter a valid Email"
            }
        }
    });

    $("#reg-form").validate({
        rules: {
            surname: {
                required: true,
                maxlength: 50
            },
            firstname: {
                required: true,
                maxlength: 50
            },
            password: {
                required: true,
                alphanumeric: true,
                maxlength: 50,
                minlength: 7
            },
            password2: {
                equalTo: "#newpassword"
            },
            email: {
                email: true
            },
            postcode: {
                postcodeUK: true
            }
        },
        messages: {
            surname: {
                maxlength: "Surname cannot be longer than 50 letters"
            },
            password: {
                maxlength: "Passwords cannot be longer than 50 characters",
                minlength: "Passwords must be 7 characters or longer",
                alphanumeric: "Only letters and numbers are allowed"
            },
            password2: {
                equalTo: "Passwords do not match!"
            },
            email: {
                email: "Please enter a valid Email"
            },
            postcode: {
                postcodeUK: "Please enter a valid Postcode"
            }
        }
    });
});

//Jump Menus
function jumpMenu(targ, selObj, restore) {
    eval(targ + ".location='" + selObj.options[selObj.selectedIndex].value + "'");
    if (restore) selObj.selectedIndex = 0;
}
