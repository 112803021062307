$(document).ready(function() {
    if ($("#map_canvas").length) {//Google Map
        function initialize() {


            var myLatlng = new google.maps.LatLng(53.005544, -1.126957);
            var mapCenter = new google.maps.LatLng(53.005544, -1.126957);
            var mapCanvas = document.getElementById('map_canvas');
            var mapOptions = {
                center: mapCenter,
                zoom: 14,
                scrollwheel: false,
                draggable: true,
                disableDefaultUI: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }

            var map = new google.maps.Map(mapCanvas, mapOptions)

            map.set('styles', [{
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [{
                    hue: '#ffff00'
                }, {
                    saturation: 30
                }, {
                    lightness: 10
                }]
            },]);

            var contentString =
                '<div id="content">' +
                '<div id="siteNotice">' +
                '</div>' +
                '<h1 id="firstHeading" class="firstHeading">Memory Foam Mattress Nottingham</h1>' +
                '<div id="bodyContent" class="bodyContent">' +
                '<p><strong>The Cheapest Prices Ever on Earth!!!</strong></p>' +
                '<p>144a Front Street</p>' +
                '<p>Arnold</p>' +
                '<p>Nottingham</p>' +
                '<p>NG5 7EG</p>' +
                '<p><strong>01159 666 000</strong></p>' +
                '</div>' +
                '</div>';

            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });

            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                animation: google.maps.Animation.DROP,
                title: 'Memory Foam Mattress Nottingham'
            });

            setTimeout(function () {
                infowindow.open(map, marker);
            }, 1000);

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }

        google.maps.event.addDomListener(window, 'load', initialize);
    }
});
